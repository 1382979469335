<template>
    <base-model-form
        model="clients"
        v-model="form"
        model-name="Client"
        redirect="model"
        :default="model"
        :saving.sync="saving"
    >
        <div class="card card-client" >
            <div class="card-content">
                <div class="columns">
                    <div class="column">
                        <base-field label="CSP" name="csp" horizontal>
                            <b-select id="csp" placeholder="Sélectionner un CSP" expanded v-model="form.csp">
                                <option
                                    v-for="(contract, contractkey) in contracts"
                                    :value="contractkey"
                                    :key="contractkey"
                                >
                                    {{ contract }}
                                </option>
                            </b-select>
                        </base-field>
                    </div>
                    <div class="column">
                        &nbsp;
                    </div>
                </div>
                <div v-if="form.csp !== 'PRIVATE'" class="columns">
                    <div class="column large">
                        <base-field label="Raison sociale" name="social_reason" horizontal>
                            <b-input
                                id="social_reason"
                                v-model="form.social_reason"
                                expanded
                                :disabled="form.csp === 'PRIVATE'"
                            ></b-input>
                        </base-field>
                    </div>
                </div>
                <div v-if="form.csp !== 'PRIVATE'" class="columns">
                    <div class="column large">
                        <base-field label="Site" name="entry_site" horizontal>
                            <b-input
                                id="entry_site"
                                v-model="form.entry_site"
                                expanded
                                :disabled="form.csp === 'PRIVATE'"
                            ></b-input>
                        </base-field>
                    </div>
                </div>
                <div v-if="form.csp !== 'PRIVATE'" class="columns">
                    <h3>Contact sur le site&nbsp;:</h3>
                </div>
                <div class="columns">
                    <div class="column">
                        <base-field label="Civilité" name="civility" horizontal>
                            <b-radio-button
                                v-for="(libelle, code) in civilities"
                                :key="code"
                                v-model="form.civility"
                                :native-value="code"
                            >
                                <span v-if="code == 'MAN'" :data-libelle="libelle">Monsieur</span>
                                <span v-if="code == 'WOMAN'" :data-libelle="libelle">Madame</span>
                            </b-radio-button>
                        </base-field>
                    </div>
                    <div class="column">
                        &nbsp;
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <base-field label="Nom*" name="lastname" horizontal>
                            <b-input id="lastname" v-model="form.lastname" type="text" required></b-input>
                        </base-field>
                    </div>
                    <div class="column">
                        <base-field label="Prénom*" name="firstname" horizontal>
                            <b-input id="firstname" v-model="form.firstname" type="text" required></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column large">
                        <base-field label="Adresse*" name="address" horizontal>
                            <b-input id="address" v-model="form.address" type="text" required></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <base-field
                            label="CP Ville*"
                            name="postcode_city"
                            horizontal
                            :message="cityValidate ? 'Le code postal vous donnera la ville si vous attendez quelques secondes.' : ''"
                        >
                            <base-place
                                id="postcode_city"
                                v-model="form.postcode_city"
                                required
                            ></base-place>
                        </base-field>
                    </div>
                    <div class="column">
                        <base-field label="IDGRC" name="idgrc" horizontal>
                            <b-input id="idgrc" type="text" v-model="form.idgrc"></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <base-field label="Téléphone*" name="phone" horizontal>
                            <b-input id="phone" v-model="form.phone" v-mask="'0# ## ## ## ##'" type="text" required></b-input>
                        </base-field>
                    </div>
                    <div class="column">
                        <base-field label="Email*" name="email" horizontal>
                            <b-input id="email" type="email" v-model="form.email" required></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns">
                    <h3>Informations commerciales&nbsp;:</h3>
                </div>
                <div v-if="user.is_super_admin || user.is_admin || user.is_user" class="columns">
                    <div class="column">
                        <base-field label="Apporteur" name="bringer_id" horizontal>
                            <base-autocomplete
                                id="bringer_id"
                                v-model="form.bringer_id"
                                model="bringers"
                                placeholder="Email"
                                :text="(model) => model.email"
                                required
                            >
                            </base-autocomplete>
                        </base-field>
                    </div>
                    <div class="column">
                        <base-field label="Numéro client" name="client_number" horizontal>
                            <b-input id="client_number" v-model="form.client_number" type="number"></b-input>
                        </base-field>
                    </div>
                </div>
                <div v-if="user.is_super_admin || user.is_admin || user.is_user" class="columns">
                    <div class="column">
                        <b-field label="Par quel moyen avez-vous connu l'apporteur ?" label-for="type">
                            <b-select
                                id="bringer_know"
                                expanded
                                v-model="form.bringer_know"
                                placeholder="Sélectionner une option"
                            >
                                <option
                                    v-for="(name, value) in bringerKnows"
                                    :value="value"
                                    :key="value"
                                >
                                    {{ name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                <div class="columns" v-if="form.bringer_know == 'parrainer'">
                    <div class="column">
                        <base-field label="Nom du parrain" name="parrainer_name">
                            <b-input id="parrainer_name" v-model="form.parrainer_name" type="text"></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns" v-if="form.bringer_know == 'other'">
                    <div class="column">
                        <base-field label="Précisez" name="bringer_know_other">
                            <b-input id="bringer_know_other" v-model="form.bringer_know_other" type="text"></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns" v-if="form.bringer_know == 'recovery'">
                    <div class="column">
                        <base-field label="Nom de l'ancien client" name="recovery_client">
                            <b-input id="recovery_client" v-model="form.recovery_client" type="text"></b-input>
                        </base-field>
                    </div>
                </div>
                <div v-if="!this.$route.path.includes('edit')" class="columns" style="display: none;">
                    <div class="column large wrap">
                        <base-field label="Équipements*" name="equipments" horizontal>
                            <b-checkbox-button
                                v-for="(product, value) in equipments"
                                :key="value"
                                v-model="form.equipments"
                                :native-value="value"
                            >
                                {{ product }}
                            </b-checkbox-button>
                        </base-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <base-field label="Commentaire" name="comment">
                            <b-input id="comment" v-model="form.comment" type="textarea"></b-input>
                        </base-field>
                    </div>
                </div>
                <div class="columns" v-if="nvu">
                    <div class="column">
                        <b-field>
                            <div>
                                <b-checkbox v-model="consent">Avant de valider, je confirme que mon client a confirmé son accord pour être contacté par notre partenaire TLB</b-checkbox>
                            </div>
                        </b-field>
                    </div>
                </div>
            </div>
            <div class="submit-button">
                <div class="double-button">
                    <back-button />
                    <b-button
                        native-type="submit"
                        type="is-primary"
                        :disabled="cityValidate || (nvu && !consent)"
                        :loading="saving"
                    >
                        <span v-if="model">Mettre à jour</span>
                        <span v-else>Valider</span>
                    </b-button>
                </div>
            </div>
        </div>
    </base-model-form>
</template>

<script>
import BackButton from '@/components/base/buttons/BackButton.vue'
import { mapState } from 'vuex'
import Legal from "@/views/Legal.vue";

export default {
    components: {Legal, BackButton },
    props: ['model'],
    data() {
        return {
            consent: false,
            nvu: false,
            form: {
                idgrc: null,
                client_number: null,
                csp: null,
                social_reason: null,
                entry_site: null,
                civility: null,
                firstname: null,
                lastname: null,
                address: null,
                postcode_city: null,
                phone: null,
                email: null,
                comment: null,
                bringer_id: null,
                bringer_know: null,
                bringer_know_other: null,
                parrainer_name: null,
                recovery_client: null,
                equipments: [],
                status: null,
            },
            formFake: {
                idgrc: '12345',
                client_number: '45678',
                csp: 'PRIVATE',
                social_reason: null,
                entry_site: null,
                civility: '',
                firstname: 'firstname',
                lastname: 'LASTNAME',
                address: 'address',
                postcode_city: '35000 - Rennes',
                phone: '06 00 00 00 00',
                email: 'user@mail.com',
                bringer_id: null,
                bringer_know: 'gan',
                //equipments: ['MONITORING_VIDEO'],
                equipments: [],
                status: 'NEW',
            },
            saving: false,
        }
    },
    mounted() {
        if (!this.$route.path.includes('edit')) {
            this.nvu = true
            if (process.env.NODE_ENV === 'development') {
                this.form = this.formFake
            }
            if ( this.user.is_bringer ) {
                // Dans le cas où un apporteur crée un client, il faut que bringer_id soit renseigné.
                this.form.bringer_id = this.user.id
            }
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            civilities: (state) => state.enums.civilities,
            contracts: (state) => state.enums.contracts,
            equipments: (state) => state.enums.ajoutables,
            bringerKnows: (state) => state.enums.bringerKnows

        }),
        cityValidate() {
            return this.form.postcode_city && this.form.postcode_city.length < 6
        },
    },
    watch: {
        /**
         * Le nom de famille doit être en majuscules.
         * @param newValue
         */
        'form.lastname'(newValue) {
            let maj = newValue.toUpperCase()
            if ( maj!==newValue ) {
                this.form.lastname = maj;
            }
        },
        /**
         * Le prénom doit commencer par une majuscule.
         * @param newValue
         */
        'form.firstname'(newValue) {
            let maj = newValue;
            if ( maj.length===1 ) {
                maj = newValue.toUpperCase()
            } else if ( maj.length>1 ) {
                maj = newValue.substring(0,1).toUpperCase() + newValue.substring(1);
            }
            if ( maj!==newValue ) {
                this.form.firstname = maj;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.large {
    /deep/ .field-body {
        flex-grow: 11.5;
    }
}
.wrap {
    /deep/ .field-body {
        flex-wrap: wrap;
    }
    /deep/ .field {
        margin: 0.25rem;
        flex-grow: unset !important;
    }
}
.card-client {
    /deep/ .field.is-horizontal {
        align-items: center;
        .field-label.is-normal {
            padding-top: 0;
        }
    }
    /deep/ .field-label .label {
        min-width: 110px;
    }
    h3 {
        padding: 0.75rem;
        font-weight: bold;
        font-size: 1.2rem;
    }
}
</style>
