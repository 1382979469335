<template>
  <div>
    <div class="equipments__add">
      <b-button size="is-small" @click="isModalActive = true"
        >+ Ajouter un équipement</b-button
      >
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <base-model-form
          model="clients"
          v-model="form"
          model-name="Équipement"
          :custom-url="`/api/clients/${$route.params.id}/equipment`"
          :saving.sync="saving"
          class="modal-card-body"
          :replace="replace"
        >
          <h2>Ajouter un nouvel équipement</h2>
          <base-field
            label="Équipement"
            name="equipment"
            horizontal
            class="mt-3"
          >
            <b-select
              id="equipment"
              placeholder="Sélectionner un équipement"
              expanded
              v-model="form.type"
              required
            >
              <option
                v-for="(equipment, key) in equipments"
                :value="key"
                :key="key"
              >
                {{ equipment }}
              </option>
            </b-select>
          </base-field>
          <div class="is-flex">
            <b-button native-type="submit" class="ml-auto" :loading="saving">
              Valider
            </b-button>
          </div>
        </base-model-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      isModalActive: false,
      form: {
        status: this.$workflow.NEW,
        type: null,
      },
      formFake: {
        status: this.$workflow.NEW,
        type: 'MONITORING_VIDEO',
      },
      saving: false,
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.form = this.formFake
    }
  },
  computed: {
    ...mapState({
      equipments: (state) => state.enums.ajoutables,
      response: (state) => state.response,
    }),
  },
  methods: {
    ...mapMutations({
      setEquipment: 'setEquipment',
    }),
    replace() {
      this.setEquipment({})
      this.$router.push({
        name: this.$route.name,
        params: { id: this.$route.params.id },
        query: { equipment: this.response.id },
      })
      this.$emit('refresh')
      this.isModalActive = false
    },
  },
}
</script>

<style lang="css" scoped>
.equipments__add {
  padding: 1rem;
  display: flex;
  button {
    margin-left: auto;
  }
}
</style>