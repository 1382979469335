<template>
  <div class="container is-fluid">
    <h1>Configuration</h1>
    <equipment-types></equipment-types>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import EquipmentTypes from '@/components/config/EquipmentTypes.vue'

export default {
  components: {
      EquipmentTypes,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
}
</script>
